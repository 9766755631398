/** This page has links to projects I want to share but not publicly. */

import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Box from "../components/box"
import CustomLink from "../components/customLink"

export default () => (
  <Box>
    <Helmet>
      <html lang="en"/>
      {/* I would add a meta charSet tag, but Gatsby seems to put one in automatically
      */}
      <link rel="canonical" href="https://www.ethanensminger.com" />
      <meta name="description" content="Other projects I want to share" />
      <title>Other Projects</title>
    </Helmet>

    <h1>Other Projects</h1>

    <CustomLink href="/private/cell-tracking" >
      Cell tracking
    </CustomLink>

    <CustomLink href="/private/file-transfer" >
      File transfer
    </CustomLink>

    <Link to="/">Back to home page</Link>
  </Box>
  )
